<template>
  <!--begin::Basic info-->
  <div
    class="card mb-5 mb-xl-10"
    style="min-width: 100%; max-width: fit-content; border-radius: 1.5rem"
  >
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Detalhes</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Form-->
      <Form
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
      >
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <!--begin::Input group-->
          <div class="row mb-8">
            <div class="col-6 col-md-auto">
              <!--begin::Label-->
              <label class="col-form-label fw-bold fs-6"
                >Número de cliente:</label
              >
              <!--end::Label-->
            </div>
            <!--begin::Col-->
            <div class="col-6 col-md-auto">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col fv-row">
                  <Field
                    readonly
                    type="text"
                    name="fclientCode"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    v-model="user.numcl"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->

              <!--end::Col-->
            </div>
            <!--begin::Input group-->
            <div class="col-6 col-md-auto">
              <!--begin::Label-->
              <label class="col-form-label fw-bold fs-6">Acesso:</label>
              <!--end::Label-->
            </div>
            <div class="col fv-row">
              <Field
                readonly
                type="text"
                name="fType"
                class="
                  form-control form-control-lg form-control-solid
                  mb-3 mb-lg-0
                "
                v-model="userType"
              />
            </div>
            <!--end::Col-->

            <!--end::Input group-->
          </div>
          <!--begin::Input group-->
          <div class="row mb-md-8">
            <!--begin::Label-->
            <label class="col col-sm-6 col-md-auto col-form-label fw-bold fs-6"
              >Nome Empresa:</label
            >
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col col-sm-6 col-md">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col fv-row">
                  <Field
                    readonly
                    type="text"
                    name="fname"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    v-model="user.nome"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-8">
            <div class="col-6 col-md-auto">
              <!--begin::Label-->
              <label class="col-auto col-form-label fw-bold fs-6"
                >Email empresa:</label
              >
              <!--end::Label-->
            </div>
            <!--begin::Col-->
            <div class="col">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col fv-row">
                  <Field
                    readonly
                    :maxlength="100"
                    type="text"
                    name="femail"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    v-model="user.email"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->

            <div class="col-6 col-md-auto">
              <!--begin::Label-->
              <label class="col-auto col-form-label fw-bold fs-6"
                >Email institucional:</label
              >
              <!--end::Label-->
            </div>
            <!--begin::Col-->
            <div class="col">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col fv-row">
                  <Field
                    readonly
                    :maxlength="60"
                    type="text"
                    name="fInstEamil"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    v-model="authUser.email"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-8">
            <div class="col-6 col-md-auto">
              <!--begin::Label-->
              <label class="col-auto col-form-label fw-bold fs-6"
                >Morada:</label
              >
              <!--end::Label-->
            </div>
            <!--begin::Col-->
            <div class="col">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col fv-row">
                  <Field
                    readonly
                    :maxlength="100"
                    type="text"
                    name="fMorada"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    v-model="user.morada"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->

            <div class="col-6 col-md-auto">
              <!--begin::Label-->
              <label class="col-auto col-form-label fw-bold fs-6"
                >Localidade:</label
              >
              <!--end::Label-->
            </div>
            <!--begin::Col-->
            <div class="col">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col fv-row">
                  <Field
                    readonly
                    :maxlength="60"
                    type="text"
                    name="fLocal"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    v-model="user.local"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-8">
            <div class="col-6 col-md-auto">
              <!--begin::Label-->
              <label class="col-auto col-form-label fw-bold fs-6"
                >Codigo postal:</label
              >
              <!--end::Label-->
            </div>
            <!--begin::Col-->
            <div class="col">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col fv-row">
                  <Field
                    readonly
                    :maxlength="100"
                    type="text"
                    name="fCodPostal"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    v-model="user.codPostal"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->

            <div class="col-6 col-md-auto">
              <!--begin::Label-->
              <label class="col-auto col-form-label fw-bold fs-6"
                >Distrito:</label
              >
              <!--end::Label-->
            </div>
            <!--begin::Col-->
            <div class="col">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col fv-row">
                  <Field
                    readonly
                    :maxlength="60"
                    type="text"
                    name="fDistrito"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    v-model="user.zona"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
        </div>
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
  <div class="row">
    <div class="col bg-light text-end">
      <button
        @click="back"
        type="button"
        class="btn-back btn"
        style="min-width: 100px"
      >
        &lt; Voltar
      </button>
      <button
        v-if="false"
        @click="saveChanges"
        type="button"
        class="btn btn-submit"
        style="min-width: 100px"
      >
        Submeter
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";

import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "account-settings",
  components: {
    Field,
    Form,
  },
  data() {
    return {
      user: Object,
      authUser: Object,
      userType: "",
    };
  },
  watch: {
    currenteUSer() {
      this.getUser();
    },
    currentAuthUser() {
      this.getAuthUser();
    },
  },
  computed: {
    currenteUSer() {
      return store.getters.currentUser;
    },
    currentAuthUser() {
      return store.getters.currentAuthUser;
    },
  },

  methods: {
    /*
    * ----------------DATA MANIPULATION----------------
    */

    setUserType(authUserStore) {
      this.authUser = authUserStore;
      if (
          authUserStore.acessoAreaComercial &&
          authUserStore.acessoAreaFinanceira
      ) {
        this.userType = "Área financeira e comercial";
        return;
      } else if (authUserStore.acessoAreaComercial) {
        this.userType = "Área comercial";
        return;
      } else if (authUserStore.acessoAreaFinanceira) {
        this.userType = "Área Financeira";
        return;
      }
      this.userType = "";
    },

    getUser() {
      if (store.getters.currentUser.nome) {
        this.user = store.getters.currentUser;
        this.originalValueStr = this.dataAsString();
      }
    },

    /*
    * ------------------API STORE------------------
    */
    saveChanges() {
      //if changes made save it on database
      if (this.dataAsString() != this.originalValueStr) {
        ApiService.setHeader();
        ApiService.update("users", this.user.customercode, this.user).then(
            () => {
              Swal.fire({
                text: "Dados alterados com sucesso",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              });
            }
        );
      }
      this.$router.go(-1);
    },

    /*
    * ------------------EMITS------------------
    */

    /*
    * -------------------UTILS--------------------
    */

    dataAsString() {
      return JSON.stringify(this.user);
    },
    getAuthUser() {
      this.setUserType(store.getters.currentAuthUser);
    },
    back() {
      this.$router.go(-1);
    },

    /*
    * -------------------STYLES-------------------
    */

  },
  mounted() {
    this.getAuthUser();
    this.getUser();
    setCurrentPageTitle("O meu perfil");
  },
});
</script>

<style scoped>
.btn-back {
  margin-right: 20px;
  background-color: #BDB4B6ff;
  color: black;
  width: 10%;
  font-size: 15px;
}
.btn-back:hover {
  background-color: #adadad;
}

.btn-submit {
  background-color: #29b3d2;
  color: white;
  width: 10%;
  font-size: 15px;
}

.btn-submit:hover {
  background-color: #23a0bb;
}
</style>